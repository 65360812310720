import React from "react";
import { Container } from "reactstrap";
import styles from "./styles.module.scss";

export default function Feefo() {
  return (
    <>
      <section className={`position-relative text-center`}>
        <Container>
          <div
            id="feefo-service-review-carousel-widgetId"
            className="feefo-review-carousel-widget-service"
          />
        </Container>
      </section>
    </>
  );
}
